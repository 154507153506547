
// @ts-nocheck


export const localeCodes =  [
  "en",
  "de",
  "es",
  "fr",
  "it",
  "ja",
  "pt",
  "zh-Hans",
  "zh-Hant"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_46json_6fbb2e26",
      load: () => import("#nuxt-i18n/6fbb2e26" /* webpackChunkName: "locale_en_46json_6fbb2e26" */),
      cache: true
    }
  ],
  de: [
    {
      key: "locale_de_46json_1b45727d",
      load: () => import("#nuxt-i18n/1b45727d" /* webpackChunkName: "locale_de_46json_1b45727d" */),
      cache: true
    }
  ],
  es: [
    {
      key: "locale_es_46json_b2c508a6",
      load: () => import("#nuxt-i18n/b2c508a6" /* webpackChunkName: "locale_es_46json_b2c508a6" */),
      cache: true
    }
  ],
  fr: [
    {
      key: "locale_fr_46json_d645f32f",
      load: () => import("#nuxt-i18n/d645f32f" /* webpackChunkName: "locale_fr_46json_d645f32f" */),
      cache: true
    }
  ],
  it: [
    {
      key: "locale_it_46json_8c4de671",
      load: () => import("#nuxt-i18n/8c4de671" /* webpackChunkName: "locale_it_46json_8c4de671" */),
      cache: true
    }
  ],
  ja: [
    {
      key: "locale_ja_46json_9301f455",
      load: () => import("#nuxt-i18n/9301f455" /* webpackChunkName: "locale_ja_46json_9301f455" */),
      cache: true
    }
  ],
  pt: [
    {
      key: "locale_pt_46json_4c0069b8",
      load: () => import("#nuxt-i18n/4c0069b8" /* webpackChunkName: "locale_pt_46json_4c0069b8" */),
      cache: true
    }
  ],
  "zh-Hans": [
    {
      key: "locale_zh_45hans_46json_c002387d",
      load: () => import("#nuxt-i18n/c002387d" /* webpackChunkName: "locale_zh_45hans_46json_c002387d" */),
      cache: true
    }
  ],
  "zh-Hant": [
    {
      key: "locale_zh_45hant_46json_1a7cd3e5",
      load: () => import("#nuxt-i18n/1a7cd3e5" /* webpackChunkName: "locale_zh_45hant_46json_1a7cd3e5" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/6489cccb" /* webpackChunkName: "config_i18n_46config_46ts_6489cccb" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      name: "English",
      language: "en",
      files: [
        "/src/Frontend/i18n/locales/en.json"
      ]
    },
    {
      code: "de",
      name: "Deutsch",
      language: "de",
      files: [
        "/src/Frontend/i18n/locales/de.json"
      ]
    },
    {
      code: "es",
      name: "Español",
      language: "es",
      files: [
        "/src/Frontend/i18n/locales/es.json"
      ]
    },
    {
      code: "fr",
      name: "Français",
      language: "fr",
      files: [
        "/src/Frontend/i18n/locales/fr.json"
      ]
    },
    {
      code: "it",
      name: "Italiano",
      language: "it",
      files: [
        "/src/Frontend/i18n/locales/it.json"
      ]
    },
    {
      code: "ja",
      name: "日本語",
      language: "ja",
      files: [
        "/src/Frontend/i18n/locales/ja.json"
      ]
    },
    {
      code: "pt",
      name: "Português",
      language: "pt",
      files: [
        "/src/Frontend/i18n/locales/pt.json"
      ]
    },
    {
      code: "zh-Hans",
      name: "简体中文",
      language: "zh-Hans",
      files: [
        "/src/Frontend/i18n/locales/zh-hans.json"
      ]
    },
    {
      code: "zh-Hant",
      name: "繁體中文",
      language: "zh-Hant",
      files: [
        "/src/Frontend/i18n/locales/zh-hant.json"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix",
  lazy: true,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "ecialn",
    cookieSecure: false,
    fallbackLocale: "en",
    redirectOn: "no prefix",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    name: "English",
    language: "en",
    files: [
      {
        path: "/src/Frontend/i18n/locales/en.json",
        cache: undefined
      }
    ]
  },
  {
    code: "de",
    name: "Deutsch",
    language: "de",
    files: [
      {
        path: "/src/Frontend/i18n/locales/de.json",
        cache: undefined
      }
    ]
  },
  {
    code: "es",
    name: "Español",
    language: "es",
    files: [
      {
        path: "/src/Frontend/i18n/locales/es.json",
        cache: undefined
      }
    ]
  },
  {
    code: "fr",
    name: "Français",
    language: "fr",
    files: [
      {
        path: "/src/Frontend/i18n/locales/fr.json",
        cache: undefined
      }
    ]
  },
  {
    code: "it",
    name: "Italiano",
    language: "it",
    files: [
      {
        path: "/src/Frontend/i18n/locales/it.json",
        cache: undefined
      }
    ]
  },
  {
    code: "ja",
    name: "日本語",
    language: "ja",
    files: [
      {
        path: "/src/Frontend/i18n/locales/ja.json",
        cache: undefined
      }
    ]
  },
  {
    code: "pt",
    name: "Português",
    language: "pt",
    files: [
      {
        path: "/src/Frontend/i18n/locales/pt.json",
        cache: undefined
      }
    ]
  },
  {
    code: "zh-Hans",
    name: "简体中文",
    language: "zh-Hans",
    files: [
      {
        path: "/src/Frontend/i18n/locales/zh-hans.json",
        cache: undefined
      }
    ]
  },
  {
    code: "zh-Hant",
    name: "繁體中文",
    language: "zh-Hant",
    files: [
      {
        path: "/src/Frontend/i18n/locales/zh-hant.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/