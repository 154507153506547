import { type VariantProps, cva } from "class-variance-authority";
import type { ToastRootProps } from "reka-ui";
import type { HTMLAttributes } from "vue";

export const alertVariants = cva("relative rounded-lg border border-gray-200 p-4", {
    variants: {
        variant: {
            default: "bg-white text-gray-950",
            danger: "text-danger border-danger",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});

export type AlertVariants = VariantProps<typeof alertVariants>;

export const badgeVariants = cva(
    "inline-flex space-x-2 items-center whitespace-nowrap rounded-md px-2.5 py-0.5 text-sm transition-colors select-none focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2",
    {
        variants: {
            variant: {
                default: "",
                primary: "bg-primary",
                accent: "bg-accent",
                success: "bg-success",
                warning: "bg-warning",
                danger: "bg-danger",
                outline: "border",
                highlight: "bg-accent-highlight border",
            },
            fill: {
                light: "text-gray-950",
                dark: "text-white",
            },
        },
        compoundVariants: [
            { variant: "default", fill: "light", class: "text-gray-900 bg-gray-200" },
            { variant: "default", fill: "dark", class: "bg-gray-400" },
            { variant: "primary", fill: "light", class: "bg-primary-100 text-primary-800" },
            { variant: "primary", fill: "dark", class: "bg-primary-600 text-primary-50" },
            { variant: "accent", fill: "light", class: "bg-accent-secondary" },
            { variant: "outline", fill: "light", class: "border-gray-100 text-gray-800" },
            { variant: "outline", fill: "dark", class: "border-gray-200 text-gray-950" },
            { variant: "success", fill: "light", class: "bg-success-200 text-success-900" },
            { variant: "warning", fill: "light", class: "bg-warning-200 text-warning-900" },
            { variant: "warning", fill: "dark", class: "bg-warning text-gray-950" },
            { variant: "danger", fill: "light", class: "bg-danger-200 text-danger-900" },
            { variant: "highlight", fill: "light", class: "bg-accent-highlight text-gray-600 border-gray-100" },
            { variant: "highlight", fill: "dark", class: "bg-accent-highlight text-gray-950  border-gray-400" },
        ],
        defaultVariants: {
            variant: "default",
            fill: "dark",
        },
    }
);

export type BadgeVariants = VariantProps<typeof badgeVariants>;

export const buttonVariants = cva(
    "inline-flex space-x-2 font-medium items-center justify-center whitespace-nowrap ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    {
        variants: {
            variant: {
                default: "",
                accent: "text-accent",
                primary: "text-primary",
                success: "text-success",
                danger: "text-danger",
                warning: "text-warning",
                link: "text-link bg-transparent hover:text-link-hover hover:underline",
                white: "",
            },
            size: {
                xs: "text-xs rounded-sm p-2",
                sm: "h-8 text-xs rounded-sm px-4",
                md: "h-10 text-sm px-4 rounded py-1",
                lg: "h-11 text-base rounded-md px-4",
                xl: "h-14 text-lg rounded-lg px-6",
            },
            fill: {
                none: "border",
                solid: "text-white hover:text-white",
            },
        },
        compoundVariants: [
            { variant: "default", fill: "none", class: "border-gray-200 hover:bg-gray-100" },
            {
                variant: "default",
                fill: "solid",
                class: "text-gray-900 bg-gray-200 hover:bg-gray-100 hover:text-gray-900",
            },
            { variant: "accent", fill: "none", class: "border-accent hover:bg-gray-100" },
            { variant: "accent", fill: "solid", class: "bg-accent" },
            { variant: "primary", fill: "none", class: "border-primary hover:bg-primary-100 " },
            { variant: "primary", fill: "solid", class: "bg-primary hover:bg-primary-700" },
            { variant: "success", fill: "none", class: "border-success hover:bg-success-50" },
            { variant: "success", fill: "solid", class: "bg-success hover:bg-success-700" },
            { variant: "warning", fill: "none", class: "border-warning hover:bg-warning-50" },
            {
                variant: "warning",
                fill: "solid",
                class: "text-gray-900 bg-warning hover:bg-warning-600",
            },
            { variant: "danger", fill: "none", class: "border-danger hover:bg-danger-50" },
            { variant: "danger", fill: "solid", class: "bg-danger hover:bg-danger-700" },
            { variant: "link", fill: "none", class: "border-0" },
            { variant: "link", fill: "solid", class: "text-link hover:text-link-hover" },
            { variant: "white", fill: "none", class: "border-0" },
            {
                variant: "white",
                fill: "solid",
                class: "bg-white text-gray-900 border-0 hover:text-gray-900 hover:bg-gray-100",
            },
        ],
        defaultVariants: {
            variant: "default",
            size: "md",
            fill: "none",
        },
    }
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;

export const spinnerVariants = cva("inline-block animate-spin will-change-transform", {
    variants: {
        variant: {
            primary: "text-primary",
            accent: "text-accent",
            success: "text-success",
            danger: "text-danger",
            warning: "text-warning",
            white: "text-white",
            black: "text-black",
        },
        size: {
            xs: "h-6 w-6",
            sm: "h-8 w-8",
            md: "h-10 w-10",
            lg: "h-12 w-12",
            xl: "h-20 w-20",
        },
    },
    defaultVariants: {
        variant: "primary",
        size: "md",
    },
});

export type SpinnerVariants = VariantProps<typeof spinnerVariants>;

export const toastVariants = cva(
    "group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border border-gray-200 p-6 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[--radix-toast-swipe-end-x] data-[swipe=move]:translate-x-[--radix-toast-swipe-move-x] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full dark:border-gray-800",
    {
        variants: {
            variant: {
                default: "border bg-white text-gray-950 dark:bg-gray-950 dark:text-gray-50",
                danger: "border-danger-500 bg-danger-500 text-gray-50",
                warning: "border-warning-300 bg-warning-300",
                success: "border-success-500 bg-success-500 text-gray-50",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
);

export type ToastVariants = VariantProps<typeof toastVariants>;

export interface ToastProps extends ToastRootProps {
    class?: HTMLAttributes["class"];
    variant?: ToastVariants["variant"];
    onOpenChange?: ((value: boolean) => void) | undefined;
}
