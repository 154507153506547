<template>
    <NuxtLayout>
        <NuxtPage />
        <Toaster />
    </NuxtLayout>
</template>
<script setup lang="ts">
import type { Script } from "@unhead/vue";

const client = import.meta.client;
const { t, locale } = useI18n();
const i18nHead = useLocaleHead({ seo: true });
const config = useRuntimeConfig();
const route = useRoute();
const initializeState = useInitializeState();
const { cdnUrl, enableDatadome, enableAds } = config.public;
const { userInfo } = await initializeState();
const { gtag } = useGtag();
const cookieConsent = useCookieConsent();

const preconnectLinks = [];
const clientScripts = [];
const serverScripts: Script[] = [
    // The following is used for search ads, including here so it's in the app shell no matter what
    { src: "https://securepubads.g.doubleclick.net/tag/js/gpt.js", async: true, crossorigin: "anonymous", key: "gpt" },
];

// The following is used for search ads, including here so it's in the app shell no matter what
if (enableAds) {
    serverScripts.push({
        src: "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
        async: true,
        crossorigin: "anonymous",
        key: "gpt",
    });
}

if (userInfo.value.AcceptsAnalyticsCookies) {
    clientScripts.push({
        src: "//assets.adobedtm.com/175f7caa2b90/392d111f1591/launch-0ed7304f3505.min.js",
        async: true,
    });
} else {
    gtag("consent", "default", { ad_storage: "denied", analytics_storage: "denied" });
}

if (enableDatadome) {
    serverScripts.push({
        innerHTML:
            '!function (a, b, c, d, e, f) { a.ddjskey = e; a.ddoptions = f || null; var m = b.createElement(c), n = b.getElementsByTagName(c)[0]; m.async = 1, m.src = d, n.parentNode.insertBefore(m, n);}(window, document, "script", "https://dd.trustedparts.com/tags.js", "7012F816DF6737C9087618574AE3B2", {endpoint: "https://dd.trustedparts.com/js/", ajaxListenerPath : true });',
    });
    preconnectLinks.push({ rel: "preconnect", href: "https://dd.trustedparts.com" });
}

useHead({
    titleTemplate: (title) => {
        if (!title) title = t("Global.TitleDefault");
        return title.includes("TrustedParts.com") ? title : title + " " + t("Global.TitleSuffix");
    },
    script: clientScripts,
});

if (import.meta.server) {
    useHead({
        htmlAttrs: {
            lang: locale.value,
        },
        link: [
            { rel: "apple-touch-icon", sizes: "180x180", href: cdnUrl + "/apple-touch-icon.png" },
            { rel: "manifest", href: cdnUrl + "/site.webmanifest" },
            { rel: "mask-icon", href: cdnUrl + "/safari-pinned-tab.svg", color: "#00274e" },
            ...(i18nHead.value.link || []).filter((link) => link.hid !== "i18n-xd"), // remove default language alternate link
        ].concat(preconnectLinks),
        meta: [
            { name: "msapplication-TileColor", content: "#00274e" },
            { name: "theme-color", content: "#00274e" },
            ...(i18nHead.value.meta || []),
        ],
        script: serverScripts,
    });
}

// Global SEO tags common to all pages.
useServerSeoMeta({
    ogType: "website",
    ogSiteName: "TrustedParts.com",
    ogImage: cdnUrl + "/assets/images/trusted-parts-logo-home.png",
});

if (client && (userInfo.value.IsCookieConsentNeeded || route.query["load-cookie-consent"])) {
    cookieConsent.init();
}
</script>
